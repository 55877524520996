<template>
  <div class="top-search-input ml-8 mt-2">
    <v-autocomplete
      v-model="value"
      :items="menuItems"
      item-text="title"
      dense
      :label="$t('common.search')"
      @update:modelValue="selected"
    >
      <template v-slot:menu-icon>
        <FluentIcon icon="fluent:alert-32-regular" color="white" large />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import menu from '@/router/menu';
import { mapGetters } from 'vuex';

export default {
  name: 'TopSearch',

  data() {
    return {
      value: null,
    };
  },

  computed: {
    ...mapGetters({
      roles: 'auth/roles',
    }),

    filteredMenu() {
      if (this.roles.includes('BackOffice.Administrator')) return menu;

      const res = menu.map(element => {
        return {
          ...element,
          children: element.children.filter(children =>
            children.authorized.some(r => this.roles.includes(r)),
          ),
        };
      });

      const filtered = res.map(element => {
        return {
          ...element,
          moduleDisplay: element.children.length >= 1,
        };
      });

      return filtered;
    },

    menuItems() {
      let routes = [...this.filteredMenu];
      let res = [];
      routes.map(r => {
        r.children.forEach(el => {
          res.push({ title: this.$t(`menu.${el.title}`), value: el.to });
        });
      });
      return res;
    },
  },

  methods: {
    selected() {
      this.$router.push(this.value);
      this.value = null;
    },
  },
};
</script>
