import { http } from '@/plugins/http';
import i18n from '@/translation';

export default {
  namespaced: true,

  state() {
    return {
      capsulesEndpoint: 'synchronisation/api/capsules/filters',
      capsulesSynchronizeEndpoint:
        'synchronisation/api/capsules/synchronize/aurion',
      capsules: [],
    };
  },

  getters: {
    capsulesEndpoint: state => state.capsulesEndpoint,
    capsulesSynchronizeEndpoint: state => state.capsulesSynchronizeEndpoint,
    capsules: state => state.capsules,
  },

  actions: {
    getCapsules({ commit, state }, params) {
      return http
        .post(state.capsulesEndpoint, params)
        .then(response => {
          commit('setCapsules', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    resyncCapsule({ state, dispatch }, aurionCapsule) {
      return http
        .post(state.capsulesSynchronizeEndpoint, aurionCapsule)
        .then(() => {
          const snackbar = {
            timeout: 4000,
            type: 'success',
            text: i18n.global.t('snackbar.resyncCapsule', {
              title: aurionCapsule.cleUniqueCapsule,
            }),
          };

          dispatch('ui/displaySnackbar', snackbar, { root: true });
        })
        .catch(error => {
          dispatch('error/setApiError', false, { root: true });

          const snackbar = {
            timeout: 4000,
            type: 'error',
            text: `${error}`,
          };

          dispatch('ui/displaySnackbar', snackbar, { root: true });
        });
    },
  },

  mutations: {
    setCapsules(state, capsules) {
      state.capsules = capsules;
    },
  },
};
