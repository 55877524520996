<template>
  <v-icon :icon="icon" :color="color" :large="large" v-if="isCustom"></v-icon>
  <Icon
    :icon="icon"
    :color="color"
    :class="[
      large ? 'large' : '',
      medium ? 'medium' : '',
      small ? 'small' : '',
    ]"
    v-else
  />
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'FluentIcon',

  components: { Icon },

  props: {
    icon: {
      type: String,
    },

    color: {
      type: String,
      default: 'white',
    },

    large: {
      type: Boolean,
      default: false,
    },

    medium: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isCustom() {
      return this.icon.includes('$');
    },
  },
};
</script>

<style lang="scss" scoped>
.large {
  font-size: 28px;
}

.medium {
  font-size: 22px;
}

.small {
  font-size: 16px;
}
</style>
