<template>
  <v-app-bar flat>
    <v-row justify="space-between" align="center">
      <TopSearch />

      <div class="d-flex align-center">
        <v-btn color="primary" rounded @click.prevent="todo()">
          <FluentIcon icon="fluent:alert-28-regular" color="white" large />
        </v-btn>
        <TopLocaleSelector />
        <v-divider class="my-5" inset vertical></v-divider>
        <TopAccountMenu />
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
import TopSearch from './TopSearch.vue';
import TopAccountMenu from './TopAccountMenu.vue';
import TopLocaleSelector from './TopLocaleSelector.vue';

export default {
  name: 'TopNavigation',

  components: {
    TopSearch,
    TopAccountMenu,
    TopLocaleSelector,
  },

  methods: {
    todo() {},
  },
};
</script>
