import moment from 'moment';

const filters = {
  // String filters
  capitalize(value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  },

  lowercase(value) {
    if (!value) return '';
    value = value.toString();
    return value.toLowerCase();
  },

  uppercase(value) {
    if (!value) return '';
    value = value.toString();
    return value.toUpperCase();
  },

  // Date filters
  formatDate(date, locale, format) {
    return moment(date).locale(locale).format(format);
  },
};

export default filters;
