const rooms = {
  planning: 'Planning des salles',
  campus: 'Campus',
  rooms: 'Salles',
  room: 'Salle',
  disponibilities: 'Disponibilités des salles sur un campus',
  seats: 'Places',
};

export default rooms;
