import { PublicClientApplication } from '@azure/msal-browser';

/**
 * Interceptor will setup api token and
 * catch global api errors.
 */
const setup = (axios, store, emitter) => {
  axios.interceptors.request.use(
    request => {
      store.dispatch('ui/toggleOverlay');
      store.dispatch('error/setApiError', false);

      const token = localStorage.userToken
        ? JSON.parse(localStorage.getItem('userToken'))
        : store.state.auth.accessToken;

      if (token) {
        request.headers['Authorization'] = 'Bearer ' + token;
      }

      return request;
    },

    error => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    response => {
      store.dispatch('ui/toggleOverlay');
      store.dispatch('error/setApiError', false);
      return response;
    },

    /* Handle all error messages
     * generated by HTTP requests.
     */
    error => {
      store.dispatch('ui/toggleOverlay');

      const { response } = error;

      const msalInstance = new PublicClientApplication(
        store.state.auth.msalConfig,
      );

      switch (true) {
        /**
         * User is no longer authenticated.
         */
        case response.status === 401:
          store.dispatch('ui/showOverlay');

          store.dispatch('auth/getAccessToken', msalInstance).then(() => {
            // Reprocess last API action
            store
              .dispatch(
                store.state.auth.lastAction,
                store.state.auth.lastActionPayload,
              )
              .finally(() => {
                store.dispatch('ui/hideOverlay');
              });
          });
          break;

        /**
         * User does not have access to the resource.
         */
        case response.status === 403:
          store.dispatch('error/setApiError', true);
          emitter.emit('api-error', 'error.api.unauthorized');
          store.dispatch('auth/signIn', msalInstance);
          break;

        /**
         * Ressource does not exists.
         */
        case response.status === 404:
          store.dispatch('error/setApiError', true);
          emitter.emit('api-error', 'error.api.notfound');
          break;

        /**
         * Other api errors.
         */
        default:
          store.dispatch('error/setApiError', true);
          emitter.emit('api-error', 'error.api.unknown');
          break;
      }

      /**
       * Return rejected promise.
       */
      return Promise.reject(error);
    },
  );
};

export default setup;
