import PassThrough from '@/components/PassThrough.vue';

const reports = {
  path: '/reports/',
  name: 'Reports',
  meta: {
    requiredAuth: true,
  },
  component: PassThrough,
  children: [
    {
      path: '',
      component: () => {
        return import('@/views/Ward/Reports/Reports.vue');
      },
      meta: {
        authorized: ['BackOffice.Validator'],
      },
    },
    {
      path: ':id',
      component: () => {
        return import('@/views/Ward/Reports/ReportDetails.vue');
      },
      meta: {
        authorized: ['BackOffice.Validator'],
      },
    },
  ],
};

export default reports;
