import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      groupsEndpoint: 'academic/api/groups',
    };
  },

  getters: {
    groupsEndpoint: state => state.groupsEndpoint,
  },

  actions: {
    getGroups({ state }, params) {
      return http
        .post(`${state.groupsEndpoint}/filters`, params)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {},
};
