import { createStore } from 'vuex';

import auth from './modules/auth';
import error from './modules/error';
import ui from './modules/ui';

// Plugins
import beforeEachAction from './plugins/beforeEachAction';

// API related
import assistances from './modules/api/assistances';
import campus from './modules/api/campus';
import capsules from './modules/api/capsules';
import countries from './modules/api/countries';
import groups from './modules/api/groups';
import hobbies from './modules/api/hobbies';
import rooms from './modules/api/rooms';
import sessions from './modules/api/sessions';
import reports from './modules/api/reports';

export default createStore({
  plugins: [beforeEachAction],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    error,
    ui,

    // API related
    assistances,
    campus,
    capsules,
    countries,
    groups,
    hobbies,
    rooms,
    sessions,
    reports,
  },
});
