<template>
  <v-row class="login-container ma-0">
    <v-col cols="4" class="login-col pa-0">
      <div class="logo-banner">
        <div class="logo-emn-container">
          <span class="logo-emn" aria-hidden="true">
            <svg
              id="Calque_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 524.511 524.51"
              xml:space="preserve"
            >
              <rect
                style="fill: rgb(234, 0, 34)"
                width="524.511"
                height="524.51"
              ></rect>
              <g>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M97.496,117.982H81.287c0,0,16.208,8.752,16.208,22.368v172.134 c0,13.615-16.208,22.368-16.208,22.368h16.208h4.538h37.604V117.982h-37.604H97.496z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M427.013,312.483c0-0.743-0.069-1.333-0.162-1.867V142.217c0.093-0.535,0.162-1.124,0.162-1.868 c0-13.615,16.209-22.368,16.209-22.368h-16.371h-4.376h-37.765v8.105v184.53c-0.093,0.535-0.163,1.124-0.163,1.867 c0,13.615-16.207,22.368-16.207,22.368h16.37h4.376h33.389h4.376h16.371C443.222,334.851,427.013,326.098,427.013,312.483z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M169.565,220.743c5.735,1.351,34.46,9.214,42.038,32.822v-32.822v-0.243v-32.822 c-7.578,23.609-36.303,31.471-42.038,32.822h-1.076c0,0,0.223,0.047,0.557,0.121c-0.335,0.075-0.557,0.121-0.557,0.121H169.565z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M168.489,334.851h78.448v-63.213C246.937,271.638,229.351,322.819,168.489,334.851z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M362.341,197.728c-6.202,11.057-19.327,30.869-23.575,37.234l-79.187-116.98h-37.646H209.01h-18.964 c0,0,23.074,6.17,34.183,22.829l100.834,151.25l20.098-47.653C345.16,244.408,369.798,184.437,362.341,197.728z"
                ></path>
              </g>
              <g>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M97.824,407.666L84.53,386.069h-0.14l0.187,21.597h-7.744V374.64h9.096l13.247,21.551h0.141 l-0.187-21.551h7.743v33.026H97.824z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M157.437,391.014c0,2.611-0.451,4.999-1.353,7.159c-0.903,2.162-2.154,4.012-3.756,5.552 c-1.602,1.539-3.506,2.728-5.714,3.567c-2.209,0.84-4.602,1.261-7.184,1.261c-2.581,0-4.968-0.42-7.16-1.261 c-2.192-0.839-4.089-2.028-5.69-3.567c-1.602-1.54-2.854-3.39-3.755-5.552c-0.903-2.16-1.353-4.548-1.353-7.159 c0-2.643,0.45-5.031,1.353-7.16c0.901-2.13,2.153-3.942,3.755-5.435c1.601-1.492,3.498-2.643,5.69-3.451 c2.193-0.808,4.579-1.213,7.16-1.213c2.581,0,4.975,0.404,7.184,1.213c2.207,0.808,4.112,1.959,5.714,3.451 c1.602,1.492,2.853,3.305,3.756,5.435C156.986,385.983,157.437,388.371,157.437,391.014z M148.901,391.014 c0-1.43-0.233-2.767-0.7-4.012c-0.467-1.243-1.112-2.31-1.936-3.195c-0.824-0.887-1.819-1.587-2.985-2.099 s-2.449-0.769-3.848-0.769c-1.4,0-2.675,0.256-3.825,0.769c-1.15,0.513-2.146,1.213-2.985,2.099 c-0.84,0.885-1.485,1.952-1.936,3.195c-0.451,1.245-0.676,2.582-0.676,4.012c0,1.492,0.233,2.869,0.699,4.128 c0.467,1.259,1.112,2.34,1.936,3.241c0.823,0.903,1.811,1.61,2.962,2.123c1.15,0.513,2.425,0.769,3.825,0.769 c1.4,0,2.674-0.256,3.825-0.769c1.15-0.513,2.146-1.22,2.985-2.123c0.84-0.901,1.492-1.982,1.959-3.241 C148.668,393.883,148.901,392.506,148.901,391.014z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M189.716,407.666l-7.171-13.108h-2.719v13.108h-7.79V374.64h12.594c1.586,0,3.132,0.164,4.641,0.49 c1.508,0.327,2.861,0.879,4.058,1.656c1.197,0.778,2.153,1.804,2.869,3.079c0.714,1.275,1.072,2.862,1.072,4.758 c0,2.239-0.606,4.121-1.819,5.643c-1.213,1.524-2.892,2.612-5.038,3.266l8.63,14.134H189.716z M189.389,384.763 c0-0.778-0.165-1.407-0.493-1.889c-0.328-0.482-0.751-0.855-1.266-1.12c-0.516-0.264-1.094-0.443-1.734-0.536 c-0.641-0.094-1.258-0.141-1.852-0.141h-4.265v7.696h3.796c0.656,0,1.328-0.054,2.016-0.162c0.688-0.109,1.312-0.304,1.875-0.584 c0.562-0.28,1.023-0.684,1.382-1.213C189.209,386.286,189.389,385.602,189.389,384.763z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M242.238,407.666l0.186-23.369h-0.14l-8.582,23.369h-5.598l-8.35-23.369h-0.14l0.187,23.369h-7.51 V374.64h11.349l7.519,21.178h0.187l7.192-21.178h11.536v33.026H242.238z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M288.557,407.666l-2.565-6.484h-12.782l-2.425,6.484h-8.676l13.854-33.026h7.743l13.712,33.026 H288.557z M279.693,383.457l-4.196,11.335h8.302L279.693,383.457z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M330.491,407.666l-13.295-21.597h-0.139l0.185,21.597h-7.743V374.64h9.096l13.247,21.551h0.141 l-0.187-21.551h7.743v33.026H330.491z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M386.512,391.06c0,2.892-0.538,5.387-1.61,7.486c-1.074,2.099-2.489,3.825-4.245,5.178 c-1.757,1.352-3.74,2.347-5.948,2.985s-4.447,0.956-6.717,0.956h-12.314V374.64h11.942c2.333,0,4.634,0.272,6.904,0.817 c2.269,0.543,4.29,1.446,6.064,2.705c1.772,1.259,3.202,2.947,4.292,5.061C385.967,385.339,386.512,387.951,386.512,391.06z  M378.208,391.06c0-1.866-0.303-3.412-0.91-4.642c-0.606-1.227-1.416-2.207-2.425-2.938c-1.011-0.73-2.162-1.252-3.451-1.562 c-1.291-0.311-2.621-0.467-3.989-0.467h-3.964v19.311h3.777c1.43,0,2.807-0.162,4.129-0.49c1.321-0.326,2.486-0.862,3.498-1.61 c1.01-0.746,1.82-1.74,2.425-2.985C377.906,394.435,378.208,392.895,378.208,391.06z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M401.111,407.666V374.64h8.024v33.026H401.111z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M425.274,407.666V374.64h22.204v6.717H432.97v6.25h13.714v6.391H432.97v6.858h15.347v6.81H425.274z"
                ></path>
              </g>
              <g>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M92.784,444.813c0,1.158-0.23,2.132-0.689,2.922c-0.46,0.788-1.069,1.423-1.828,1.902 c-0.759,0.479-1.623,0.824-2.592,1.033c-0.969,0.21-1.962,0.315-2.981,0.315h-8.448v-21.211h8.448c0.819,0,1.658,0.085,2.517,0.255 c0.858,0.17,1.637,0.46,2.337,0.869c0.698,0.41,1.267,0.964,1.708,1.663c0.439,0.699,0.659,1.588,0.659,2.666 c0,1.18-0.32,2.152-0.959,2.922c-0.639,0.768-1.478,1.324-2.516,1.662v0.06c0.599,0.101,1.163,0.285,1.693,0.554 c0.529,0.27,0.988,0.614,1.378,1.034c0.389,0.419,0.698,0.909,0.929,1.467C92.669,443.485,92.784,444.114,92.784,444.813z  M86.972,435.915c0-0.719-0.275-1.243-0.824-1.572c-0.55-0.329-1.363-0.495-2.441-0.495h-2.517v4.375h2.756 c0.959,0,1.703-0.196,2.232-0.584C86.707,437.248,86.972,436.674,86.972,435.915z M87.661,444.334c0-0.879-0.329-1.488-0.989-1.827 c-0.659-0.34-1.538-0.509-2.636-0.509h-2.846v4.853h2.877c0.399,0,0.814-0.035,1.243-0.105c0.429-0.069,0.819-0.194,1.169-0.374 c0.349-0.18,0.634-0.434,0.854-0.764C87.551,445.277,87.661,444.853,87.661,444.334z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M120.091,446.476c-0.429,1.049-1.044,1.948-1.842,2.696c-0.799,0.75-1.763,1.334-2.891,1.752 c-1.129,0.42-2.382,0.63-3.76,0.63c-1.398,0-2.656-0.21-3.775-0.63c-1.119-0.419-2.067-1.003-2.846-1.752 c-0.778-0.748-1.377-1.647-1.798-2.696c-0.419-1.049-0.629-2.213-0.629-3.49v-13.212h5.093v12.792c0,0.58,0.075,1.129,0.225,1.649 c0.15,0.519,0.379,0.984,0.688,1.393c0.31,0.41,0.719,0.734,1.229,0.974c0.509,0.239,1.124,0.36,1.842,0.36 c0.719,0,1.334-0.121,1.843-0.36c0.509-0.24,0.924-0.564,1.243-0.974c0.319-0.409,0.55-0.873,0.689-1.393s0.21-1.069,0.21-1.649 v-12.792h5.123v13.212C120.735,444.263,120.52,445.427,120.091,446.476z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M142.665,435.286c-0.439-0.56-1.014-1.004-1.723-1.334c-0.709-0.329-1.393-0.493-2.052-0.493 c-0.34,0-0.684,0.029-1.034,0.089c-0.35,0.06-0.664,0.176-0.944,0.345c-0.28,0.17-0.514,0.388-0.703,0.659 c-0.19,0.269-0.285,0.614-0.285,1.033c0,0.36,0.075,0.659,0.224,0.899c0.15,0.24,0.37,0.449,0.66,0.629 c0.289,0.18,0.634,0.345,1.034,0.495c0.399,0.15,0.848,0.305,1.348,0.465c0.719,0.239,1.468,0.504,2.246,0.793 c0.779,0.291,1.488,0.675,2.128,1.154c0.639,0.479,1.168,1.075,1.588,1.782c0.419,0.709,0.629,1.594,0.629,2.651 c0,1.219-0.225,2.273-0.674,3.161c-0.45,0.889-1.054,1.623-1.813,2.203c-0.76,0.58-1.629,1.008-2.607,1.288 c-0.978,0.281-1.987,0.42-3.025,0.42c-1.518,0-2.987-0.265-4.404-0.794c-1.418-0.529-2.596-1.283-3.535-2.262l3.355-3.415 c0.519,0.639,1.203,1.172,2.052,1.603c0.849,0.43,1.693,0.645,2.532,0.645c0.379,0,0.749-0.04,1.108-0.121 c0.36-0.079,0.674-0.209,0.944-0.388s0.484-0.42,0.644-0.719c0.16-0.299,0.24-0.659,0.24-1.079c0-0.399-0.1-0.738-0.299-1.019 c-0.2-0.279-0.485-0.534-0.854-0.764c-0.37-0.229-0.829-0.439-1.378-0.629c-0.55-0.19-1.173-0.394-1.872-0.614 c-0.679-0.22-1.344-0.479-1.993-0.78c-0.649-0.299-1.228-0.683-1.737-1.152s-0.919-1.039-1.229-1.708 c-0.31-0.669-0.465-1.483-0.465-2.443c0-1.178,0.24-2.187,0.719-3.025s1.108-1.528,1.887-2.067s1.657-0.934,2.636-1.183 c0.978-0.25,1.967-0.375,2.966-0.375c1.198,0,2.422,0.22,3.67,0.66c1.248,0.439,2.341,1.088,3.281,1.946L142.665,435.286z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M155.756,450.985v-21.211h5.153v21.211H155.756z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M185.355,450.985l-8.538-13.871h-0.089l0.119,13.871h-4.973v-21.211h5.842l8.508,13.841h0.09 l-0.119-13.841h4.973v21.211H185.355z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M202.132,450.985v-21.211h14.26v4.314h-9.317v4.014h8.808v4.104h-8.808v4.405h9.857v4.373H202.132z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M238.113,435.286c-0.439-0.56-1.014-1.004-1.723-1.334c-0.709-0.329-1.393-0.493-2.052-0.493 c-0.34,0-0.684,0.029-1.034,0.089s-0.664,0.176-0.944,0.345c-0.28,0.17-0.514,0.388-0.703,0.659 c-0.19,0.269-0.285,0.614-0.285,1.033c0,0.36,0.075,0.659,0.224,0.899c0.15,0.24,0.37,0.449,0.66,0.629 c0.289,0.18,0.634,0.345,1.034,0.495c0.399,0.15,0.848,0.305,1.348,0.465c0.719,0.239,1.468,0.504,2.246,0.793 c0.779,0.291,1.488,0.675,2.128,1.154c0.639,0.479,1.168,1.075,1.588,1.782c0.419,0.709,0.629,1.594,0.629,2.651 c0,1.219-0.225,2.273-0.674,3.161c-0.45,0.889-1.054,1.623-1.813,2.203c-0.76,0.58-1.629,1.008-2.607,1.288 c-0.978,0.281-1.987,0.42-3.025,0.42c-1.518,0-2.987-0.265-4.404-0.794c-1.419-0.529-2.596-1.283-3.535-2.262l3.355-3.415 c0.519,0.639,1.203,1.172,2.052,1.603s1.693,0.645,2.532,0.645c0.379,0,0.749-0.04,1.108-0.121c0.36-0.079,0.674-0.209,0.944-0.388 c0.27-0.18,0.484-0.42,0.644-0.719c0.16-0.299,0.24-0.659,0.24-1.079c0-0.399-0.1-0.738-0.299-1.019 c-0.2-0.279-0.485-0.534-0.854-0.764c-0.37-0.229-0.829-0.439-1.378-0.629c-0.55-0.19-1.173-0.394-1.872-0.614 c-0.679-0.22-1.344-0.479-1.993-0.78c-0.649-0.299-1.228-0.683-1.737-1.152s-0.919-1.039-1.229-1.708 c-0.31-0.669-0.465-1.483-0.465-2.443c0-1.178,0.24-2.187,0.719-3.025s1.108-1.528,1.887-2.067s1.657-0.934,2.636-1.183 c0.978-0.25,1.967-0.375,2.966-0.375c1.198,0,2.422,0.22,3.67,0.66c1.248,0.439,2.341,1.088,3.281,1.946L238.113,435.286z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M262.408,435.286c-0.439-0.56-1.014-1.004-1.723-1.334c-0.709-0.329-1.393-0.493-2.052-0.493 c-0.34,0-0.684,0.029-1.034,0.089s-0.664,0.176-0.944,0.345c-0.28,0.17-0.514,0.388-0.703,0.659 c-0.19,0.269-0.285,0.614-0.285,1.033c0,0.36,0.075,0.659,0.224,0.899c0.15,0.24,0.37,0.449,0.66,0.629 c0.289,0.18,0.634,0.345,1.034,0.495c0.399,0.15,0.848,0.305,1.348,0.465c0.719,0.239,1.468,0.504,2.246,0.793 c0.779,0.291,1.488,0.675,2.128,1.154c0.639,0.479,1.168,1.075,1.587,1.782c0.42,0.709,0.63,1.594,0.63,2.651 c0,1.219-0.226,2.273-0.675,3.161c-0.449,0.889-1.053,1.623-1.812,2.203c-0.76,0.58-1.629,1.008-2.607,1.288 c-0.978,0.281-1.987,0.42-3.025,0.42c-1.518,0-2.987-0.265-4.404-0.794c-1.419-0.529-2.596-1.283-3.535-2.262l3.355-3.415 c0.519,0.639,1.203,1.172,2.052,1.603c0.849,0.43,1.693,0.645,2.532,0.645c0.379,0,0.749-0.04,1.108-0.121 c0.36-0.079,0.674-0.209,0.944-0.388s0.484-0.42,0.644-0.719c0.16-0.299,0.24-0.659,0.24-1.079c0-0.399-0.1-0.738-0.299-1.019 c-0.2-0.279-0.485-0.534-0.854-0.764c-0.37-0.229-0.829-0.439-1.378-0.629c-0.55-0.19-1.173-0.394-1.872-0.614 c-0.679-0.22-1.344-0.479-1.993-0.78c-0.649-0.299-1.228-0.683-1.737-1.152s-0.919-1.039-1.229-1.708 c-0.31-0.669-0.465-1.483-0.465-2.443c0-1.178,0.24-2.187,0.719-3.025s1.108-1.528,1.887-2.067s1.657-0.934,2.636-1.183 c0.978-0.25,1.967-0.375,2.966-0.375c1.198,0,2.422,0.22,3.67,0.66c1.248,0.439,2.341,1.088,3.281,1.946L262.408,435.286z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M300.787,435.286c-0.44-0.56-1.014-1.004-1.723-1.334c-0.709-0.329-1.393-0.493-2.051-0.493 c-0.341,0-0.685,0.029-1.034,0.089c-0.35,0.06-0.663,0.176-0.944,0.345c-0.279,0.17-0.514,0.388-0.703,0.659 c-0.19,0.269-0.285,0.614-0.285,1.033c0,0.36,0.075,0.659,0.224,0.899c0.15,0.24,0.37,0.449,0.659,0.629 c0.289,0.18,0.634,0.345,1.034,0.495c0.398,0.15,0.849,0.305,1.348,0.465c0.719,0.239,1.467,0.504,2.247,0.793 c0.778,0.291,1.488,0.675,2.126,1.154c0.639,0.479,1.168,1.075,1.588,1.782c0.42,0.709,0.629,1.594,0.629,2.651 c0,1.219-0.224,2.273-0.673,3.161c-0.45,0.889-1.055,1.623-1.813,2.203c-0.76,0.58-1.629,1.008-2.607,1.288 c-0.978,0.281-1.987,0.42-3.025,0.42c-1.518,0-2.987-0.265-4.404-0.794c-1.418-0.529-2.597-1.283-3.536-2.262l3.356-3.415 c0.519,0.639,1.203,1.172,2.051,1.603s1.693,0.645,2.532,0.645c0.378,0,0.748-0.04,1.108-0.121c0.36-0.079,0.675-0.209,0.944-0.388 c0.27-0.18,0.485-0.42,0.645-0.719c0.16-0.299,0.24-0.659,0.24-1.079c0-0.399-0.101-0.738-0.301-1.019 c-0.2-0.279-0.483-0.534-0.853-0.764c-0.37-0.229-0.829-0.439-1.378-0.629s-1.174-0.394-1.873-0.614 c-0.679-0.22-1.342-0.479-1.991-0.78c-0.65-0.299-1.229-0.683-1.738-1.152s-0.919-1.039-1.229-1.708 c-0.309-0.669-0.465-1.483-0.465-2.443c0-1.178,0.24-2.187,0.719-3.025s1.109-1.528,1.887-2.067s1.657-0.934,2.637-1.183 c0.978-0.25,1.967-0.375,2.965-0.375c1.198,0,2.421,0.22,3.67,0.66c1.249,0.439,2.342,1.088,3.28,1.946L300.787,435.286z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M329.066,450.611c-1.378,0.629-2.965,0.944-4.763,0.944c-1.637,0-3.151-0.27-4.539-0.81 c-1.388-0.538-2.591-1.302-3.61-2.292c-1.019-0.988-1.818-2.171-2.397-3.549c-0.58-1.378-0.869-2.896-0.869-4.555 c0-1.698,0.295-3.235,0.883-4.614c0.59-1.378,1.403-2.551,2.443-3.52c1.037-0.968,2.256-1.712,3.654-2.231s2.906-0.778,4.525-0.778 c1.498,0,2.969,0.265,4.418,0.793c1.449,0.529,2.621,1.303,3.52,2.322l-3.476,3.476c-0.479-0.659-1.108-1.148-1.887-1.467 c-0.778-0.319-1.577-0.48-2.395-0.48c-0.899,0-1.723,0.165-2.472,0.495c-0.75,0.329-1.394,0.784-1.934,1.364 c-0.539,0.578-0.958,1.263-1.257,2.051c-0.301,0.79-0.45,1.653-0.45,2.591c0,0.96,0.15,1.839,0.45,2.637 c0.299,0.798,0.714,1.483,1.243,2.051c0.529,0.57,1.162,1.014,1.902,1.334c0.739,0.319,1.548,0.479,2.427,0.479 c1.019,0,1.908-0.199,2.666-0.598c0.76-0.4,1.368-0.919,1.829-1.558l3.565,3.355C331.602,449.127,330.444,449.98,329.066,450.611z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M354.711,450.985v-8.868h-8.269v8.868h-5.123v-21.211h5.123v8.029h8.269v-8.029h5.123v21.211 H354.711z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M392.91,440.29c0,1.677-0.291,3.21-0.869,4.598c-0.58,1.388-1.384,2.577-2.413,3.565 c-1.029,0.99-2.251,1.754-3.67,2.292c-1.418,0.539-2.955,0.81-4.614,0.81c-1.657,0-3.189-0.27-4.598-0.81 c-1.408-0.538-2.627-1.302-3.656-2.292c-1.029-0.988-1.833-2.177-2.411-3.565c-0.58-1.388-0.869-2.92-0.869-4.598 c0-1.698,0.289-3.231,0.869-4.599c0.578-1.368,1.383-2.532,2.411-3.49c1.029-0.958,2.247-1.698,3.656-2.217 s2.941-0.778,4.598-0.778c1.659,0,3.195,0.259,4.614,0.778s2.641,1.259,3.67,2.217s1.833,2.122,2.413,3.49 C392.619,437.059,392.91,438.592,392.91,440.29z M387.427,440.29c0-0.919-0.15-1.778-0.45-2.577 c-0.299-0.798-0.714-1.483-1.243-2.053c-0.529-0.568-1.168-1.019-1.918-1.348c-0.748-0.329-1.572-0.493-2.472-0.493 c-0.898,0-1.718,0.164-2.456,0.493c-0.739,0.329-1.378,0.78-1.918,1.348c-0.539,0.57-0.954,1.254-1.243,2.053 c-0.291,0.798-0.434,1.657-0.434,2.577c0,0.958,0.15,1.841,0.449,2.651c0.299,0.809,0.714,1.502,1.243,2.082 c0.529,0.58,1.164,1.033,1.903,1.362c0.738,0.331,1.558,0.495,2.456,0.495c0.899,0,1.718-0.164,2.457-0.495 c0.738-0.329,1.378-0.783,1.918-1.362c0.539-0.58,0.958-1.273,1.257-2.082C387.277,442.131,387.427,441.248,387.427,440.29z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M424.995,440.29c0,1.677-0.291,3.21-0.869,4.598c-0.58,1.388-1.384,2.577-2.413,3.565 c-1.029,0.99-2.251,1.754-3.67,2.292c-1.418,0.539-2.955,0.81-4.614,0.81c-1.657,0-3.189-0.27-4.598-0.81 c-1.408-0.538-2.627-1.302-3.656-2.292c-1.029-0.988-1.833-2.177-2.411-3.565c-0.58-1.388-0.869-2.92-0.869-4.598 c0-1.698,0.289-3.231,0.869-4.599c0.578-1.368,1.383-2.532,2.411-3.49s2.247-1.698,3.656-2.217s2.941-0.778,4.598-0.778 c1.659,0,3.195,0.259,4.614,0.778c1.419,0.519,2.641,1.259,3.67,2.217c1.029,0.958,1.833,2.122,2.413,3.49 C424.705,437.059,424.995,438.592,424.995,440.29z M419.513,440.29c0-0.919-0.15-1.778-0.45-2.577 c-0.299-0.798-0.714-1.483-1.243-2.053c-0.529-0.568-1.168-1.019-1.918-1.348c-0.748-0.329-1.572-0.493-2.472-0.493 c-0.898,0-1.718,0.164-2.456,0.493c-0.739,0.329-1.378,0.78-1.918,1.348c-0.539,0.57-0.954,1.254-1.243,2.053 c-0.291,0.798-0.434,1.657-0.434,2.577c0,0.958,0.15,1.841,0.449,2.651c0.299,0.809,0.714,1.502,1.243,2.082 c0.529,0.58,1.164,1.033,1.903,1.362c0.738,0.331,1.558,0.495,2.456,0.495c0.899,0,1.718-0.164,2.457-0.495 c0.738-0.329,1.378-0.783,1.918-1.362c0.539-0.58,0.958-1.273,1.257-2.082C419.363,442.131,419.513,441.248,419.513,440.29z"
                ></path>
                <path
                  style="fill: rgb(0, 34, 102)"
                  d="M434.971,450.985v-21.211h5.153v16.747h8.209v4.464H434.971z"
                ></path>
              </g>
              <g>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M227.66,67.288V54.382l-3.277,2.532l-2.159-2.954l5.781-4.245h3.799v17.573H227.66z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M258.709,62.274c0,0.927-0.182,1.734-0.545,2.42c-0.364,0.687-0.847,1.258-1.45,1.713 c-0.603,0.455-1.305,0.794-2.105,1.017c-0.801,0.224-1.631,0.335-2.49,0.335c-0.876,0-1.71-0.112-2.502-0.335 c-0.793-0.223-1.491-0.558-2.094-1.005c-0.602-0.447-1.085-1.013-1.449-1.7c-0.364-0.686-0.545-1.493-0.545-2.42 c0-0.53,0.083-1.021,0.248-1.477c0.166-0.455,0.393-0.86,0.683-1.216c0.289-0.356,0.624-0.661,1.005-0.918 c0.38-0.256,0.786-0.451,1.216-0.583v-0.05c-0.761-0.281-1.398-0.74-1.911-1.377c-0.513-0.637-0.769-1.444-0.769-2.42 c0-0.811,0.165-1.53,0.496-2.16c0.331-0.628,0.777-1.154,1.34-1.576c0.562-0.422,1.216-0.74,1.961-0.956 c0.745-0.215,1.522-0.322,2.333-0.322c0.811,0,1.584,0.103,2.321,0.31c0.736,0.207,1.385,0.517,1.948,0.931 c0.562,0.414,1.014,0.93,1.353,1.551c0.339,0.62,0.509,1.337,0.509,2.147c0,0.977-0.248,1.796-0.745,2.457 c-0.496,0.662-1.142,1.126-1.936,1.39v0.075c0.443,0.133,0.853,0.327,1.232,0.583c0.377,0.256,0.706,0.562,0.984,0.918 c0.28,0.356,0.501,0.761,0.665,1.216C258.627,61.278,258.709,61.762,258.709,62.274z M254.564,62.126 c0-0.678-0.228-1.245-0.682-1.7c-0.456-0.455-1.039-0.682-1.75-0.682c-0.728,0-1.32,0.223-1.774,0.67 c-0.456,0.446-0.683,1.017-0.683,1.713c0,0.711,0.227,1.278,0.683,1.7c0.454,0.422,1.046,0.632,1.774,0.632 c0.728,0,1.316-0.211,1.763-0.632C254.34,63.404,254.564,62.837,254.564,62.126z M254.241,54.482c0-0.58-0.207-1.059-0.62-1.44 c-0.414-0.38-0.911-0.571-1.489-0.571c-0.629,0-1.134,0.191-1.514,0.571c-0.38,0.381-0.571,0.861-0.571,1.44 c0,0.562,0.186,1.05,0.559,1.464c0.372,0.414,0.881,0.62,1.526,0.62c0.612,0,1.117-0.206,1.514-0.62 C254.043,55.532,254.241,55.044,254.241,54.482z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M275.19,67.288h-4.841l7.124-13.998h-8.317v-3.574h12.759v3.326L275.19,67.288z"
                ></path>
                <path
                  style="fill: rgb(255, 255, 255)"
                  d="M298.098,67.288V54.382l-3.276,2.532l-2.16-2.954l5.781-4.245h3.8v17.573H298.098z"
                ></path>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </span>
        </div>
        <div class="logo-title-container">
          <h1 class="logo-title">EMN Tools</h1>
        </div>
      </div>
      <ChasingDotsSpinner
        width="80"
        height="80"
        textTag="h2"
        text="common.connectionPending"
        customClass="login-title"
      />
    </v-col>
    <v-col cols="8" class="title-col pa-0">
      <div class="bubbling"></div>
      <h2>{{ $t('common.welcome') }}</h2>
    </v-col>
  </v-row>
</template>

<script>
import ChasingDotsSpinner from '@/components/loaders/ChasingDotsSpinner.vue';

export default {
  name: 'LoginLayout',

  components: {
    ChasingDotsSpinner,
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100vh;

  .login-col {
    background-color: $primary-darken;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;

    .logo-banner {
      flex-direction: row;
      margin-bottom: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 0px 0px;

      .logo-emn-container {
        position: relative;

        .logo-emn {
          display: inline-block;
          height: 78px;
          width: 78px;

          &::after {
            content: '';
            position: absolute;
            top: 0px;
            width: 1px;
            height: 55px;
            background: rgba(255, 255, 255, 0.34);
            left: 0px;
            margin: 20px 0px 0px 127px;
          }
        }
      }

      .logo-title-container {
        padding: 0px 25px 0px 85px;

        .logo-title {
          color: $white;
        }
      }
    }

    .login-btn {
      padding: 14px 62px;
      font-size: 13px;
    }
  }

  .title-col {
    background-image: url('@/assets/img/login/login-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;

    &::before {
      content: '';
      background-image: url('@/assets/img/login/medium-round.png');
      background-repeat: no-repeat;
      position: absolute;
      width: 88px;
      height: 175px;
      margin-top: 256px;
      right: 0px;
    }

    &::after {
      content: '';
      background-image: url('@/assets/img/login/blur-circle.png');
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 250px;
      right: 33%;
    }

    .bubbling {
      position: relative;

      &::before {
        content: '';
        background-image: url('@/assets/img/login/small-round.png');
        background-repeat: no-repeat;
        position: absolute;
        width: 120px;
        margin-top: 612px;
        height: 120px;
        left: 22%;
      }

      &::after {
        content: '';
        background-image: url('@/assets/img/login/big-round.png');
        background-repeat: no-repeat;
        position: absolute;
        width: 300px;
        height: 300px;
        margin-top: 444px;
        right: 20px;
      }
    }

    h2 {
      color: $white;
      padding: 222px 0px 0px 120px;
      margin-bottom: 400px;
      max-width: 676px;
      font-weight: 100;
      font-size: 50px;
      line-height: 50px;
    }
  }
}
</style>
