import axios from 'axios';

/**
 * Axios default options
 * Set base URL
 */
export const http = axios.create({
  baseURL: process.env.VUE_APP_API_MANAGEMENT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Helper to set token
 * in each axios request
 */
export function setTokenHeader(token) {
  token !== null
    ? (http.defaults.headers.common['Authorization'] = `Bearer ${token}`)
    : delete http.defaults.headers.common['Authorization'];
}

export default http;
