import PassThrough from '@/components/PassThrough.vue';

const synchronisations = {
  path: '/synchronisations/',
  name: 'Synchronisations',
  meta: {
    requiredAuth: true,
  },
  component: PassThrough,
  children: [
    {
      path: 'capsules',
      component: () => {
        return import('@/views/Synchronisations/Capsules.vue');
      },
      meta: {
        authorized: ['BackOffice.Debugger'],
      },
    },
    {
      path: 'sessions',
      component: () => {
        return import('@/views/Synchronisations/Sessions.vue');
      },
      meta: {
        authorized: ['BackOffice.Debugger'],
      },
    },
  ],
};

export default synchronisations;
