<template>
  <v-app class="dashboard-layout">
    <SideNavigation />
    <TopNavigation />
    <v-main>
      <div class="main-content ma-5 pa-5">
        <slot />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SideNavigation from './SideNavigation/SideNavigation.vue';
import TopNavigation from './TopNavigation/TopNavigation.vue';

export default {
  name: 'DashboardLayout',

  components: {
    SideNavigation,
    TopNavigation,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-layout {
  background-color: $primary;

  .main-content {
    background-color: $primary-darken;
    border-radius: 20px;
    position: relative;
  }
}
</style>
