const menu = {
  dashboard: 'Dashboard',
  error: 'Erreur',
  home: 'Accueil',
  moodle: 'Moodle',
  zoom: 'Zoom',
  jobs: 'Web Jobs',
  referencials: 'Référentiels',
  assistances: 'Assistances',
  countries: 'Pays',
  hobbies: "Centres d'intérêts",
  synchronisations: 'Synchronisations',
  capsules: 'Capsules',
  sessions: 'Sessions',
  rooms: 'Salles',
  planning: 'Planning',
  disponibilities: 'Disponibilités',
  reports: 'Signalements',
  ward: 'Ward',
};

export default menu;
