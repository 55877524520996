<template>
  <v-snackbar
    class="v-snackbar--top"
    v-model="showSnackbar"
    :timeout="snackbar.timeout"
    :color="snackbar.type"
  >
    {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn @click="hideSnackbar">
        <FluentIcon icon="fluent:dismiss-24-filled" color="white" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CustomSnackbar',

  computed: {
    ...mapGetters({
      showSnackbar: 'ui/showSnackbar',
      snackbar: 'ui/snackbar',
    }),
  },

  methods: {
    ...mapActions({
      hideSnackbar: 'ui/hideSnackbar',
    }),
  },
};
</script>
