import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      campusEndpoint: 'campus/api/campus',
      campus: [],
    };
  },

  getters: {
    campusEndpoint: state => state.campusEndpoint,
    campus: state => state.campus,
  },

  actions: {
    getCampus({ commit, state }) {
      return http
        .post(`${state.campusEndpoint}/filters`, {})
        .then(response => {
          commit('setCampus', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getCampusForPlanning({ commit, state, dispatch }) {
      return http
        .post(`${state.campusEndpoint}/filters`, {})
        .then(response => {
          commit('setCampus', response.data);
          dispatch('rooms/getRooms', null, { root: true });
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getSessionsByCampusId({ state }, params) {
      return http
        .get(`${state.campusEndpoint}/${params.id}/sessions`, {
          params: params.params,
          headers: params.headers,
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getTeachersByCampusId({ state }, params) {
      return http
        .get(`${state.campusEndpoint}/${params.id}/sessions/users/teachers`, {
          params: params.params,
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {
    setCampus(state, campus) {
      state.campus = campus;
    },
  },
};
