export default {
  namespaced: true,

  state() {
    return {
      apiError: false,
    };
  },

  getters: {
    apiError: state => state.apiError,
  },

  actions: {
    setApiError({ commit }, apiError) {
      commit('setApiError', apiError);
    },
  },

  mutations: {
    setApiError(state, apiError) {
      state.apiError = apiError;
    },
  },
};
