import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      roomsEndpoint: 'campus/api/rooms',
      rooms: [],
    };
  },

  getters: {
    roomsEndpoint: state => state.roomsEndpoint,
    rooms: state => state.rooms,
  },

  actions: {
    getRooms({ commit, state }) {
      return http
        .post(`${state.roomsEndpoint}/filters`, {})
        .then(response => {
          commit('setRooms', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getSessionsByRoomId({ state }, params) {
      return http
        .get(`${state.roomsEndpoint}/${params.id}/sessions`, {
          params: params.params,
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getTeachersByRoomId({ state }, params) {
      return http
        .get(`${state.roomsEndpoint}/${params.id}/sessions/users/teachers`, {
          params: params.params,
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {
    setRooms(state, rooms) {
      state.rooms = rooms;
    },
  },
};
