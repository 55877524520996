const synchronisations = {
  moduleKey: 'synchronisations',
  moduleTitle: 'Synchonisations',
  moduleDisplay: true,
  children: [
    {
      title: 'capsules',
      icon: '$aurion',
      to: '/synchronisations/capsules',
      authorized: ['BackOffice.Debugger'],
    },
    {
      title: 'sessions',
      icon: '$ubicast',
      to: '/synchronisations/sessions',
      authorized: ['BackOffice.Debugger'],
    },
  ],
};

export default synchronisations;
