const reports = {
  moduleKey: 'ward',
  moduleTitle: 'Ward',
  moduleDisplay: true,
  children: [
    {
      title: 'reports',
      icon: 'fluent:person-prohibited-24-regular',
      to: '/reports',
      authorized: ['BackOffice.Validator'],
    },
  ],
};

export default reports;
