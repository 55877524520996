const rooms = {
  planning: 'Rooms planning',
  campus: 'Campus',
  rooms: 'Rooms',
  room: 'Room',
  disponibilities: 'Rooms availability on campus',
  seats: 'Seats',
};

export default rooms;
