const aurion = {
  caps: 'Caps',
  mailTeacher: "Teacher's email",
  synchronizeToUbicast: 'Synchronize to Ubicast',
  aurionKey: 'Aurion key',
  emptyFuseauReference: 'Empty fuseauReference !',
  emptySessionAttendees: 'Empty sessionAttendees !',
  emptyLmsSessionsUsers: 'Empty lmsSessionsUsers !',
  searchCaps: 'Search for a cap',
};

export default aurion;
