const aurion = {
  caps: 'Capsule',
  mailTeacher: "E-mail de l'intervenant",
  synchronizeToUbicast: 'Synchroniser sur Ubicast',
  aurionKey: 'Clé Aurion',
  emptyFuseauReference: 'fuseauReference vide !',
  emptySessionAttendees: 'sessionAttendees vide !',
  emptyLmsSessionsUsers: 'lmsSessionsUsers vide !',
  searchCaps: 'Rechercher une capsule',
};

export default aurion;
