const reports = {
  content: 'Content type',
  abuse: 'Abuse type',
  infos: 'Report information',
  id: 'Report ID',
  title: 'Report title',
  desc: 'Report description',
  reporting: 'User reporting',
  reported: 'User reported',
  detail: 'Report details',
};

export default reports;
