<template>
  <v-row class="pa-4 mr-4">
    <v-col cols="2">
      <v-avatar class="avatar-container">
        <v-img
          class="avatar-img"
          src="@/assets/img/banner-bubble.png"
          alt="bubble"
        >
          <span class="white--text text-h6">{{ initials }}</span>
        </v-img>
      </v-avatar>
    </v-col>
    <v-col cols="9" class="ml-2">
      <h4 class="ml-3">{{ account.name }}</h4>
      <a @click="signOut">
        <v-list-item-title class="ml-3 sign-out-container">
          <FluentIcon
            icon="fluent:sign-out-20-regular"
            color="#999cb7"
            medium
          />
          <span class="ml-1">{{ $t('common.logout') }}</span>
        </v-list-item-title>
      </a>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TopAccountMenu',

  computed: {
    ...mapGetters({
      account: 'auth/account',
    }),

    initials() {
      const fullName = this.account.name.split(' ');
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    },
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
    }),
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 48px;
  width: 48px;

  .avatar-img {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    border: solid $white 2px;
    border-radius: 50%;
    margin: 10px;

    span {
      color: $white;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

.sign-out-container {
  display: flex;
  align-content: center;
  font-size: 14px;
  color: #999cb7;

  &:hover {
    color: $white;

    svg {
      color: $white !important;
    }
  }
}
</style>
