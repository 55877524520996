const home = {
  path: '/',
  name: 'Home',
  component: () => {
    return import('@/views/Home.vue');
  },
  meta: {
    requiredAuth: true,
    authorized: ['all'],
  },
};

export default home;
