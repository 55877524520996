<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <path
      fill-rule="evenodd"
      d="M 31 10 L 17 11 L 5 18 L 8 18 L 8 27.214844 C 7.417969 27.695313 7 28.976563 7 30.5 C 7 32.433594 7.671875 34 8.5 34 C 9.328125 34 10 32.933594 10 31 C 10 29.476563 9.582031 27.890625 9 27.277344 L 9 18 L 20 18 Z M 25.5 15 L 20.5 19 L 12.5 19 C 12.402344 19.484375 12.375 19.46875 12.375 19.980469 C 12.375 20.5 12.429688 21.007813 12.53125 21.5 C 12.808594 21.488281 18.5 21 21.519531 23.5 C 22.105469 21.726563 23.730469 19.449219 26.035156 17.378906 C 26.246094 17.1875 26.285156 17.179688 26.5 17 C 26.5 16 26.039063 15.578125 25.5 15 Z M 34 16 C 31.695313 16 29.597656 16.882813 28 18.320313 C 27.695313 18.046875 27.371094 17.804688 27.03125 17.578125 C 26.910156 17.683594 26.78125 17.785156 26.660156 17.894531 C 25.035156 19.355469 23.757813 20.917969 22.9375 22.332031 C 24.148438 22.882813 25 24.089844 25 25.5 L 25 37 L 31 37 L 31 25.5 C 31 23.574219 32.574219 22 34.5 22 C 36.425781 22 38 23.574219 38 25.5 L 38 37 L 44 37 L 44 25 C 44 20.050781 39.949219 16 35 16 Z M 12.53125 22.015625 C 12.195313 22.953125 12 23.953125 12 25 L 12 37 L 18 37 L 18 25.5 C 18 24.632813 18.332031 23.851563 18.859375 23.246094 C 17.320313 22.535156 15.066406 22.074219 12.53125 22.015625 Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'MoodleIcon',
};
</script>
