import { http } from '@/plugins/http';
import i18n from '@/translation';

export default {
  namespaced: true,

  state() {
    return {
      sessionsEndpoint: 'synchronisation/api/mssessions/filters',
      sessionsResyncEnpoint:
        'synchronisation/api/mssessions/synchronize/toubicast',
      sessions: [],
    };
  },

  getters: {
    sessionsEndpoint: state => state.sessionsEndpoint,
    sessionsResyncEnpoint: state => state.sessionsResyncEnpoint,
    sessions: state => state.sessions,
  },

  actions: {
    getSessions({ commit, state }, params) {
      return http
        .post(state.sessionsEndpoint, params)
        .then(response => {
          commit('setSessions', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    resyncSession({ state, dispatch }, session) {
      return http
        .post(state.sessionsResyncEnpoint, [session])
        .then(() => {
          const snackbar = {
            timeout: 4000,
            type: 'success',
            text: i18n.global.t('snackbar.resyncSession', {
              title: session.id,
            }),
          };

          dispatch('ui/displaySnackbar', snackbar, { root: true });
        })
        .catch(error => {
          dispatch('error/setApiError', false, { root: true });

          const snackbar = {
            timeout: 4000,
            type: 'error',
            text: `${error}`,
          };

          dispatch('ui/displaySnackbar', snackbar, { root: true });
        });
    },
  },

  mutations: {
    setSessions(state, sessions) {
      state.sessions = sessions;
    },
  },
};
