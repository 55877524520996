import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      assistancesEndpoint: 'referencials/api/assistances',
      assistances: [],
    };
  },

  getters: {
    assistancesEndpoint: state => state.assistancesEndpoint,
    assistances: state => state.assistances,
  },

  actions: {
    getAssistances({ commit, state }, params) {
      return http
        .get(state.assistancesEndpoint, { params: params })
        .then(response => {
          commit('setAssistances', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {
    setAssistances(state, assistances) {
      state.assistances = assistances;
    },
  },
};
