/**
 * Called before each store actions.
 * Store the last API related action
 * proceed so if an error occures it's
 * easier to reprocess it.
 */
export default store => {
  store.subscribeAction((action, state) => {
    if (
      !action.type.includes('ui') &&
      !action.type.includes('error') &&
      !action.type.includes('auth')
    ) {
      state.auth.lastAction = action.type;
      state.auth.lastActionPayload = action.payload;
    }
  });
};
