<template>
  <div class="spinner-container">
    <div class="spinner-chasing-dots" :style="spinnerStyle">
      <div></div>
      <div></div>
    </div>
    <component :is="textTag" class="spinner-text" :class="customClass">
      {{ $t(text) }}
    </component>
  </div>
</template>

<script>
export default {
  name: 'ChasingDotsSpinner',

  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: String,
      default: '40',
    },
    height: {
      type: String,
      default: '40',
    },
    textTag: {
      type: String,
      default: 'h4',
    },
    text: {
      type: String,
      default: 'common.loading',
    },
    customClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    spinnerStyle() {
      let color = this.color !== '' ? 'color: ' + this.color + ';' : '';
      let width = this.width !== '' ? 'width: ' + this.width + 'px;' : '';
      let height = this.height !== '' ? 'height: ' + this.height + 'px;' : '';
      return color + width + height;
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spinner-text {
    color: currentColor;

    &.login-title {
      font-weight: 100;
      margin-top: 24px;
      color: $white;
    }
  }

  .spinner-chasing-dots {
    position: relative;
    opacity: 0.6;

    -webkit-animation: spinner-rotate 2s infinite linear;
    animation: spinner-rotate 2s infinite linear;

    div {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: currentColor;
      border-radius: 100%;

      -webkit-animation: spinner-bounce 2s infinite ease-in-out;
      animation: spinner-bounce 2s infinite ease-in-out;

      &:last-child {
        top: auto;
        bottom: 0;

        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }
  }
}

@-webkit-keyframes spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes spinner-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
