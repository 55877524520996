import PassThrough from '@/components/PassThrough.vue';

const rooms = {
  path: '/rooms/',
  name: 'Rooms',
  meta: {
    requiredAuth: true,
  },
  component: PassThrough,
  children: [
    {
      path: 'planning',
      component: () => {
        return import('@/views/Rooms/Planning.vue');
      },
      meta: {
        authorized: ['all'],
      },
    },
    {
      path: 'disponibilities',
      component: () => {
        return import('@/views/Rooms/Disponibilities.vue');
      },
      meta: {
        authorized: ['all'],
      },
    },
  ],
};

export default rooms;
