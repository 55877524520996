import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import http from './plugins/http';
import setupInterceptors from './plugins/interceptors';
import filters from './plugins/filters';
import i18n from './translation';
import mitt from 'mitt';
import FluentIcon from './components/icons/FluentIcon.vue';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.vue';
import GlobalAurionCapsuleDialog from '@/components/dialogs/GlobalAurionCapsuleDialog.vue';

const app = createApp(App);

loadFonts();

// Emitter
const emitter = mitt();
app.config.globalProperties.$emitter = emitter;

// Axios
setupInterceptors(http, store, emitter);

// MSAL
app.config.globalProperties.$msalInstance = {};

// Filters
app.config.globalProperties.$filters = filters;

// Custom components
app.component('FluentIcon', FluentIcon);
app.component('LoadingOverlay', LoadingOverlay);
app.component('GlobalAurionCapsuleDialog', GlobalAurionCapsuleDialog);

app.use(i18n).use(store).use(router).use(vuetify).mount('#app');
