<template>
  <v-overlay
    class="loading-overlay"
    v-model="getBool"
    :contained="contained"
    style="border-radius: 20px"
  >
    <ChasingDotsSpinner />
  </v-overlay>
</template>

<script>
import ChasingDotsSpinner from '@/components/loaders/ChasingDotsSpinner.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingOverlay',

  components: {
    ChasingDotsSpinner,
  },

  props: {
    local: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    contained: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      overlay: 'ui/overlay',
    }),

    getBool() {
      return this.local ? this.loading : this.overlay;
    },
  },
};
</script>
