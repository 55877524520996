export default {
  namespaced: true,

  state() {
    return {
      overlay: false,
      forceOverlay: false,
      showSnackbar: false,
      snackbar: {
        timeout: 2000,
        type: 'info',
        text: '',
      },
      countries: [
        {
          locale: 'fr',
          flag: 'fr',
        },
        {
          locale: 'en',
          flag: 'gb',
        },
      ],
    };
  },

  getters: {
    overlay: state => state.overlay,
    forceOverlay: state => state.forceOverlay,
    showSnackbar: state => state.showSnackbar,
    snackbar: state => state.snackbar,
    countries: state => state.countries,
  },

  actions: {
    toggleOverlay({ commit }) {
      commit('toggleOverlay');
    },

    showOverlay({ commit }) {
      commit('setOverlay', true);
    },

    hideOverlay({ commit }) {
      commit('setOverlay', false);
    },

    displaySnackbar({ commit }, snackbar) {
      commit('setSnackbar', snackbar);
      commit('setShowSnackbar', true);
    },

    hideSnackbar({ commit }) {
      commit('setShowSnackbar', false);
    },
  },

  mutations: {
    toggleOverlay(state) {
      if (!state.forceOverlay) {
        state.overlay = !state.overlay;
      }
    },

    setOverlay(state, overlay) {
      state.forceOverlay = overlay;
      state.overlay = overlay;
    },

    setShowSnackbar(state, showSnackbar) {
      state.showSnackbar = showSnackbar;
    },

    setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
  },
};
