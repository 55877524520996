const rooms = {
  moduleKey: 'rooms',
  moduleTitle: 'Rooms',
  moduleDisplay: true,
  children: [
    {
      title: 'planning',
      icon: 'fluent:calendar-clock-24-filled',
      to: '/rooms/planning',
      authorized: ['all'],
    },
    {
      title: 'disponibilities',
      icon: 'fluent:clock-toolbox-24-regular',
      to: '/rooms/disponibilities',
      authorized: ['all'],
    },
  ],
};

export default rooms;
