const reports = {
  content: 'Type de contenu',
  abuse: "Type d'abus",
  infos: 'Informations du signalement',
  id: 'ID Signalement',
  title: 'Titre du signalement',
  desc: 'Description du signalement',
  reporting: 'Auteur du signalement',
  reported: "Auteur de l'abus",
  detail: 'Détail du signalement',
};

export default reports;
