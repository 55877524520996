<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48.000000 48.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M185 351 c-3 -5 1 -11 9 -13 11 -2 6 -22 -19 -88 -48 -127 -49 -130
-30 -130 9 0 36 20 61 45 24 25 49 45 56 45 8 0 20 -19 28 -42 11 -32 21 -44
38 -46 26 -4 26 4 -3 74 -10 23 -10 35 -2 43 15 15 1 14 -58 -4 -27 -8 -50
-13 -52 -11 -1 1 3 17 9 34 13 33 28 43 28 17 0 -14 31 -21 42 -9 13 12 -17
76 -40 85 -30 12 -59 11 -67 0z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MoodleIcon',
};
</script>
