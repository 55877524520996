<template>
  <div style="display: inline-block">
    <v-dialog v-model="dialog" scrollable class="aurion-dialog">
      <v-card style="background-color: #000739">
        <v-card-title>
          {{ $t('aurion.caps') }}
          {{ capsule.aurionCapsule.cleUniqueCapsule }}
        </v-card-title>
        <v-card-text style="overflow-y: scroll; height: 600px">
          <JsonViewer :value="sanitizeCapsule" theme="jv-dark" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click.prevent="resyncCapsule(capsule.aurionCapsule)"
          >
            <FluentIcon
              class="ml-2"
              icon="fluent:arrow-sync-24-regular"
              color="#2196F3"
              medium
            />
            Resynchroniser
          </v-btn>
          <v-btn color="error" @click="dialog = false">
            <FluentIcon
              icon="fluent:dismiss-24-filled"
              color="#B00020"
              medium
            />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import { mapActions } from 'vuex';

export default {
  name: 'AurionCapsuleDialog',

  components: {
    JsonViewer,
  },

  data() {
    return {
      dialog: false,
      capsule: null,
    };
  },

  created() {
    this.$emitter.on('global-aurion-dialog', capsule => {
      this.dialog = true;
      this.capsule = capsule;
    });
  },

  computed: {
    sanitizeCapsule() {
      const res = Object.assign({}, this.capsule);
      delete res.why;
      return res;
    },
  },

  methods: {
    ...mapActions({
      resyncCapsule: 'capsules/resyncCapsule',
    }),
  },
};
</script>
