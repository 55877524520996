import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      hobbiesEndpoint: 'referencials/api/hobbies',
      hobbies: [],
    };
  },

  getters: {
    hobbiesEndpoint: state => state.hobbiesEndpoint,
    hobbies: state => state.hobbies,
  },

  actions: {
    getHobbies({ commit, state }, params) {
      return http
        .get(`${state.hobbiesEndpoint}`, { params: params })
        .then(response => {
          commit('setHobbies', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {
    setHobbies(state, hobbies) {
      state.hobbies = hobbies;
    },
  },
};
