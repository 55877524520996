const menu = {
  dashboard: 'Dashboard',
  error: 'Error',
  home: 'Home',
  moodle: 'Moodle',
  zoom: 'Zoom',
  jobs: 'Web Jobs',
  referencials: 'Referencials',
  assistances: 'Assistances',
  countries: 'Countries',
  hobbies: 'Hobbies',
  synchronisations: 'Synchronizations',
  capsules: 'Caps',
  sessions: 'Sessions',
  rooms: 'Rooms',
  planning: 'Planning',
  disponibilities: 'Disponibilities',
  reports: 'Reports',
  ward: 'Ward',
};

export default menu;
