import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      reportsEndpoint: 'blacklist/api/reports',
      reports: [],
    };
  },

  getters: {
    reportsEndpoint: state => state.reportsEndpoint,
    reports: state => state.reports,
  },

  actions: {
    getReports({ commit, state }) {
      return http
        .post(`${state.reportsEndpoint}/filters`, { params: {} })
        .then(response => {
          commit('setReports', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getReportDetails({ state }, id) {
      return http
        .get(`${state.reportsEndpoint}/${id}`)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    updateStatus({ state }, { id, status }) {
      return http
        .patch(`${state.reportsEndpoint}/${id}/status/${status}`)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {
    setReports(state, reports) {
      state.reports = reports;
    },
  },
};
