<template>
  <v-navigation-drawer floating permanent>
    <v-list-item class="py-4 justify-center">
      <v-img
        src="@/assets/img/logo/emn-logo.png"
        alt="EMN Logo"
        :aspect-ratio="16 / 9"
        :width="130"
        @click="$router.push('/')"
        style="cursor: pointer"
      ></v-img>
    </v-list-item>

    <v-list density="compact" nav>
      <template v-for="item in filteredMenu" :key="item.moduleKey">
        <v-row no-gutters justify="center" v-if="item.moduleDisplay">
          <v-col class="emn-subheader mt-5 pl-4">
            {{ $t(`menu.${item.moduleKey}`) }}
          </v-col>
        </v-row>
        <div class="mt-5">
          <v-list-item
            v-for="i in item.children"
            :key="i.title"
            @click="$router.push(i.to)"
            :active="i.to === $route.path"
            active-class="router-active"
          >
            <template v-slot:prepend>
              <FluentIcon :icon="i.icon" color="#999cb7" medium />
            </template>
            <v-list-item-title
              class="ml-3"
              v-text="$t(`menu.${i.title}`)"
            ></v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2 text-center font-weight-thin text-disabled">
        v{{ version }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import menu from '@/router/menu';
import { mapGetters } from 'vuex';

export default {
  name: 'SideNavigation',

  computed: {
    ...mapGetters({
      roles: 'auth/roles',
    }),

    menu() {
      return menu;
    },

    filteredMenu() {
      if (this.roles.includes('BackOffice.Administrator')) return menu;

      const res = menu.map(element => {
        return {
          ...element,
          children: element.children.filter(children =>
            children.authorized.some(
              r => this.roles.includes(r) || r === 'all',
            ),
          ),
        };
      });

      const filtered = res.map(element => {
        return {
          ...element,
          moduleDisplay: element.children.length >= 1,
        };
      });

      return filtered;
    },

    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
};
</script>
