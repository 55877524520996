import PassThrough from '@/components/PassThrough.vue';

const about = {
  path: '/referencials/',
  name: 'Referencials',
  meta: {
    requiredAuth: true,
  },
  component: PassThrough,
  children: [
    {
      path: 'assistances',
      component: () => {
        return import('@/views/Referencials/Assistances.vue');
      },
      meta: {
        authorized: ['BackOffice.Validator'],
      },
    },
    {
      path: 'countries',
      component: () => {
        return import('@/views/Referencials/Countries.vue');
      },
      meta: {
        authorized: ['BackOffice.Validator'],
      },
    },
    {
      path: 'hobbies',
      component: () => {
        return import('@/views/Referencials/Hobbies.vue');
      },
      meta: {
        authorized: ['BackOffice.Validator'],
      },
    },
  ],
};

export default about;
