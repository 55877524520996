const error = {
  path: '/:catchAll(.*)',
  name: 'Error',
  component: () => {
    return import('@/views/Errors/NotFound.vue');
  },
  meta: {
    requiredAuth: true,
    authorized: ['all'],
  },
};

export default error;
