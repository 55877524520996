const referencials = {
  moduleKey: 'referencials',
  moduleTitle: 'Referencials',
  moduleDisplay: true,
  children: [
    {
      title: 'assistances',
      icon: 'fluent:chat-help-24-regular',
      to: '/referencials/assistances',
      authorized: ['BackOffice.Validator'],
    },
    {
      title: 'countries',
      icon: 'fluent:earth-24-filled',
      to: '/referencials/countries',
      authorized: ['BackOffice.Validator'],
    },
    {
      title: 'hobbies',
      icon: 'fluent:sport-soccer-24-regular',
      to: '/referencials/hobbies',
      authorized: ['BackOffice.Validator'],
    },
  ],
};

export default referencials;
