<template>
  <div class="py-4">
    <v-menu close-on-content-click offset-y>
      <template v-slot:activator="{ props }">
        <v-btn color="primary" rounded v-bind="props">
          <country-flag class="ma-0" :country="getFlag(locale)" rounded />
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in oppositeLocales" :key="index">
          <v-list-item-title>
            <v-btn color="primary" @click.prevent="setLocale(item)">
              <country-flag class="ma-0" :country="getFlag(item)" rounded />
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next';
import { mapGetters } from 'vuex';

export default {
  name: 'TopLocaleSelector',

  components: {
    CountryFlag,
  },

  computed: {
    ...mapGetters({
      countries: 'ui/countries',
    }),

    locale() {
      return this.$i18n.locale;
    },

    oppositeLocales() {
      let ol = [...this.$i18n.availableLocales];
      const idx = ol.indexOf(this.locale);
      if (idx > -1) {
        ol.splice(idx, 1);
      }
      return ol;
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },

    getFlag(locale) {
      const flagIdx = this.countries.findIndex(c => c.locale === locale);
      return this.countries[flagIdx].flag;
    },
  },
};
</script>
